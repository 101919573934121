header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.logosWrapper {
  display: flex;
  align-items: end;
  flex-basis: 10%;
  align-items: center;
}

.rightButtons {
  display: flex;
  flex-basis: 40%;
  align-self: end;
  justify-content: space-between;
  align-items: center;
}
.react-terminal {
    align-items: self-start;
}

.hidden-input-wrapper {
    display: none;
}

.react-terminal-wrapper:before {
    display: none;
}